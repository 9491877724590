import axiosInstance from "@/api/axiosConfig";

export const getSummaryOverview = async (year = [], search = "") => {
	try {
		if (year.length !== 0) {
			const response = await axiosInstance.post(
				"/api/data/gpt/summary/overview/",
				{
					year: ["2024", "2025"],
					search: search,
				}
			);

			return response;
		}
	} catch (error) {
		console.log(error);
	}
};

export const getSummaryDetail = async (
	year,
	weekNum,
	search = "",
	subCategoriesSelected = {}
) => {
	try {
		const response = await axiosInstance.post(
			"/api/data/gpt/summary/detail/",
			{
				year: year,
				week_in_year: [weekNum],
				search: search,
				category: subCategoriesSelected,
			}
		);

		return response;
	} catch (error) {
		console.log(error);
	}
};
