import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { Stack, Paper, Box, Typography } from "@mui/material";
import { ReactComponent as CaseDetailIcon } from "@/assets/caseDetail.svg";
import { getSummaryOverview } from "@/api/getSummaryAPI";
import UseCaseDataStore from "@/hooks/UseCaseDataStore";

function SmartSummary() {
	const [summaryOverview, setSummaryOverview] = useState({});
	const [currentWeek, setCurrentWeek] = useState(0);
	const currentYear = DateTime.now().year;
	const currentMonth = DateTime.now().month;

	const { setCaseData } = UseCaseDataStore();

	const {
		data: summaryOverviewData,
		isLoading: isLoadingSummaryOverviewData,
		isError: isErrorSummaryOverviewData,
		refetch: refetchSummaryOverviewData,
	} = useQuery({
		queryFn: () => getSummaryOverview([currentYear.toString()], ""),
		queryKey: ["summaryOverviewData"],
		refetchOnWindowFocus: false,
		staleTime: 300000,
	});

	useEffect(() => {
		if (summaryOverviewData) {
			// console.log('summaryOverviewData', summaryOverviewData.data.data);
			setSummaryOverview(summaryOverviewData.data.data);

			setCurrentWeek(
				Object.values(
					summaryOverviewData.data.data[currentYear][currentMonth]
				).findIndex((week) => {
					const startDate = DateTime.fromFormat(
						week.start_date,
						"yyyy/MM/dd"
					).startOf("day");
					const endDate = DateTime.fromFormat(
						week.end_date,
						"yyyy/MM/dd"
					).endOf("day");
					return (
						DateTime.now() >= startDate && DateTime.now() <= endDate
					);
				})
			);
		}
	}, [summaryOverviewData]);

	if (!summaryOverviewData || isErrorSummaryOverviewData) {
		refetchSummaryOverviewData();
	}

	return (
		<Paper
			sx={{
				m: 2,
				borderRadius: "16px",
				height: "auto",
				boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.05)",
			}}
		>
			<Stack
				sx={{
					pt: "20px",
					pb: "24px",
					borderBottom: (theme) =>
						`1px solid ${theme.palette.neutral.light}`,
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<Typography
						variant="h3"
						sx={{
							pl: "16px",
							mr: "16px",
							borderLeft: "4px solid #52697A",
							fontWeight: 400,
						}}
					>
						智慧摘要分析
					</Typography>
				</Box>
			</Stack>
			<Stack
				sx={{
					p: "20px",
				}}
			>
				{[...Object.entries(summaryOverview)]
					.reverse()
					.map(([year, months]) => (
						<Box
							sx={{
								mb: "32px",
							}}
							key={year}
						>
							<Typography
								variant="h3"
								sx={{
									color: "#52697A",
									fontWeight: 700,
								}}
							>
								{year}年度
							</Typography>
							{[...Object.entries(months)]
								.reverse()
								.map(([month, monthItem]) => {
									if (
										(year == 2024 && month > 5) ||
										(year > 2024 && month <= currentMonth)
									)
										return (
											<Box
												sx={{
													mb: "8px",
												}}
												key={month}
											>
												<Typography
													variant="h4"
													sx={{
														color: "#0A0A0A",
														fontWeight: 700,
													}}
												>
													{month}月
												</Typography>
												<Stack
													sx={{
														display: "flex",
														flexFlow: "row wrap",
														gap: "12px",
													}}
												>
													{Object.entries(
														monthItem
													).map(
														(
															[week, weekItem],
															index
														) => (
															<Box
																sx={{
																	width: "calc(100% / 6 - 12px)",
																	display:
																		"flex",
																	justifyContent:
																		"space-between",
																	backgroundColor:
																		Number(
																			month
																		) ===
																			currentMonth &&
																		index >=
																			currentWeek
																			? "#FFFFFF"
																			: index %
																					2 ===
																			  0
																			? "#F5F5F5"
																			: "#FAFAFA",
																	borderRadius:
																		"8px",
																	p: "12px",
																	gap: "7px",
																}}
																key={week}
															>
																<Box>
																	<Typography
																		variant="h5"
																		sx={{
																			color:
																				Number(
																					month
																				) ===
																					currentMonth &&
																				index >=
																					currentWeek
																					? "#D4D4D4"
																					: "#5084E9",
																			fontWeight:
																				"bold",
																		}}
																	>
																		周報告
																		{year}
																		-W{week}
																	</Typography>
																	<Typography
																		sx={{
																			color:
																				Number(
																					month
																				) ===
																					currentMonth &&
																				index >=
																					currentWeek
																					? "#D4D4D4"
																					: "#0A0A0A",
																			fontSize:
																				"10px",
																			lineHeight:
																				"15px",
																			letterSpacing:
																				".05rem",
																		}}
																	>
																		{
																			weekItem.start_date
																		}
																		~
																		{
																			weekItem.end_date
																		}
																	</Typography>
																</Box>
																<Link
																	to={`/dashboard/smart-summary/${year}-W${week}?startDate=${weekItem.start_date}&endDate=${weekItem.end_date}`}
																	style={{
																		lineHeight:
																			"normal",
																		pointerEvents:
																			Number(
																				month
																			) ===
																				currentMonth &&
																			index >=
																				currentWeek
																				? "none"
																				: "auto",
																	}}
																>
																	<CaseDetailIcon
																		fill={`${
																			Number(
																				month
																			) ===
																				currentMonth &&
																			index >=
																				currentWeek
																				? "#D4D4D4"
																				: "#737373"
																		}`}
																	/>
																</Link>
															</Box>
														)
													)}
												</Stack>
											</Box>
										);
								})}
						</Box>
					))}
			</Stack>
		</Paper>
	);
}

export default SmartSummary;
